<template>
  <page-template>
    <main-block>
      <block-content>
        <row>
          <column v-for="(category, index) in kbCategories" :key="index" lg="2" class="mb-4">
            <a href="javascript:void(0);"  @click="[getTopics(category), clicked_cat_id = category.id]">
              <div class="user-card border radius-5 p-2" :class="clicked_cat_id === category.id ? 'active_cat' : 'bg-white'">
                <div class="user-avatar" v-html="category.icon"></div>
                <div class="user-info">
                  <span class="lead-text">{{ category.name }}</span>
                </div>
              </div>
            </a>
          </column>
        </row>
      </block-content>
      <block-content v-if="kbCategory.hasOwnProperty('id')">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block">
              <div class="card">
                <div class="card-aside-wrap">
                  <div v-if="kbTopic.hasOwnProperty('id')" class="card-inner card-inner-lg">
                    <div class="nk-block-head nk-block-head-lg">
                      <div class="nk-block-between">
                        <div class="nk-block-head-content">
                          <h4 class="nk-block-title">{{kbTopic.post_title}}</h4>
                          <div class="nk-block-des">
                            <p v-html="kbTopic.post_content"/>
                          </div>
                        </div>
                        <div class="nk-block-head-content align-self-start d-lg-none">
                          <a href="#" class="toggle btn btn-icon btn-trigger mt-n1" data-target="userAside"><em class="icon ni ni-menu-alt-r"></em></a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg toggle-screen-lg">
                    <div class="card-inner-group">
                      <div class="card-inner">
                        <div class="user-card">
                          <div class="user-avatar bg-primary">
                            <span v-html="kbCategory.icon"></span>
                          </div>
                          <div class="user-info">
                            <span class="lead-text">{{kbCategory.name}}</span>
                            <span class="sub-text" v-html="kbCategory.description"/>
                          </div>
                        </div>
                      </div>

                      <div v-if="kbTopics.length > 0" class="card-inner p-0">
                        <ul class="link-list-menu">
                          <li v-for="(topic, index) in kbTopics" :key="index">
                            <a href="javascript:void(0);" :class="clicked_topic_id === topic.id ? 'active' : ''">
                              <span class="nk-menu-text text-capitalize" @click="[getTopicDetails(topic.id), clicked_topic_id = topic.id]">{{ topic.post_title }}</span>
                            </a>
                          </li>
                        </ul>
                      </div><!-- .card-inner -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {loadingController} from '@ionic/vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import {onMounted, ref} from "vue";
import axios from "@/libs/axios";
import useIonComponents from "@core/IonComponents";
import {useI18n} from "vue-i18n";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default {
    name: "KnowledgeBase",
    components: {
      PageTemplate,
        MainBlock,
        BlockContent,
        Row,
        Column,
    },
    setup() {
        const kbCategories = ref([]);
        const kbCategory = ref({});
        const kbTopics = ref([]);
        const kbTopic = ref({});
        const clicked_cat_id = ref('');
        const clicked_topic_id = ref('');
        const {IonSimpleAlert} = useIonComponents()
        const {t} = useI18n()

        const getKbCategories = async () => {
            let loader = await loadingController.create({message: t('Please wait...')})
            await loader.present()
            await axios.post(`/api/kb/categories`, {})
                .then(async(res) => {
                    if (!res.data.success) {
                        IonSimpleAlert('Error', res.data.message, 'OK')
                    } else {
                        kbCategories.value = res.data.data
                        await loader.dismiss()
                        if(res.data.data.length > 0){
                            clicked_cat_id.value = res.data.data[0].id
                            await getTopics(res.data.data[0]);
                        }
                    }
                })
                .catch(er => {
                    IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
                })
                .then(() => loader.dismiss())
        }

        const getTopics = async (category) => {
            let loader = await loadingController.create({message: t('Please wait...')})
            await loader.present()
            kbCategory.value = category
            await axios.post(`/api/kb/topics`, {id: category.id})
                .then(async(res) => {
                    if (!res.data.success) {
                        IonSimpleAlert('Error', res.data.message, 'OK')
                    } else {
                        kbTopic.value = {}
                        kbTopics.value = res.data.data
                        if (res.data.data.length > 0){
                            clicked_topic_id.value = res.data.data[0].id
                            await getTopicDetails(res.data.data[0].id)
                        }
                        await loader.dismiss()
                    }
                })
                .catch(er => {
                    IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
                })
                .then(() => loader.dismiss())
        }

        const getTopicDetails = async (id) => {
            let loader = await loadingController.create({message: t('Please wait...')})
            await loader.present()
            await axios.post(`/api/kb/topics`, {topic_id: id})
                .then(res => {
                    if (!res.data.success) {
                        IonSimpleAlert('Error', res.data.message, 'OK')
                    } else {
                        kbTopic.value = res.data.data
                        loader.dismiss()
                    }
                })
                .catch(er => {
                    IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
                })
                .then(() => loader.dismiss())
        }

        onMounted(() => {
            getKbCategories();
        })

        return {
            kbCategories,
            getTopics,
            getTopicDetails,
            getKbCategories,
            kbTopics,
            kbTopic,
            kbCategory,
            clicked_cat_id,
            clicked_topic_id,
        }
    }
}
</script>

<style scoped>
.active_cat{
    background-color: #eee8fd !important;
}
</style>
